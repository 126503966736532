@use "../../theme/colours.scss" as colours;
@import "../../theme/theme.scss";

.custom-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: map-get($theme, borderRadius, border-radius-medium);
  font-family: map-get($theme, typography, heading-compact-01, fontFamily);
  letter-spacing: map-get($theme, typography, heading-compact-01, letterSpacing);
  margin-top: map-get($theme, typography, heading-compact-01, marginTop);
  margin-bottom: map-get($theme, typography, heading-compact-01, marginBottom);
  margin-left: map-get($theme, typography, heading-compact-01, marginLeft);
  text-transform: map-get($theme, typography, heading-compact-01, textTransform);
  text-decoration: map-get($theme, typography, heading-compact-01, textDecoration);
  transition: all 0.2s ease-in-out;
  &:disabled {
    color: colours.$text-04;
    background-color: colours.$grey50;
    cursor: not-allowed;
    &:hover {
      background-color: colours.$grey50;
    }
  }
  &.primary {
    color: colours.$text-04;
    background-color: colours.$button-01;
    &:hover {
      background-color: colours.$button-01-hover;
    }
  }
  &.secondary {
    color: colours.$text-01;
    background-color: colours.$button-02;
    &:hover {
      background-color: colours.$button-02-hover;
    }
  }
  &.outlined {
    color: colours.$button-03;
    background-color: transparent;
    border: 1px solid colours.$button-03;
    &:hover {
      color: colours.$button-03-hover;
      border: 1px solid colours.$button-03-hover;
    }
  }
  &.alert {
    color: colours.$text-04;
    background-color: colours.$support-01;
    opacity: 1;
    &:hover {
      background-color: colours.$support-01;
      opacity: 0.9;
    }
  }
  &.ghost{
    background-color: transparent;
    color: colours.$button-01;
    border: none;
    &:hover {
      background-color: transparent;
      color: colours.$button-01-hover;
      border: none;
    }
  }
  &.small {
    font-weight: map-get($theme, typography, heading-compact-01, fontWeight);
    line-height: map-get($theme, typography, heading-compact-01, lineHeight);
    font-size: map-get($theme, typography, heading-compact-01, fontSize);
    padding: 7px 12px;
    svg {
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      margin-right: 8px;
      &.right-icon{
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }
  &.medium, &.base{
    font-weight: map-get($theme, typography, heading-compact-01, fontWeight);
    line-height: map-get($theme, typography, heading-compact-01, lineHeight);
    font-size: map-get($theme, typography, heading-compact-01, fontSize);
    padding: 11px 16px;
    svg {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      margin-right: 8px;
      &.right-icon{
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }
  &.large {
    font-weight: map-get($theme, typography, heading-compact-02, fontWeight);
    line-height: map-get($theme, typography, heading-compact-02, lineHeight);
    font-size: map-get($theme, typography, heading-compact-02, fontSize);
    padding: 11px 16px;
    svg {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      margin-right: 12px;
      &.right-icon{
        margin-left: 12px;
        margin-right: 0px;
      }
    }
  }
  &:disabled {
    color: colours.$text-04;
    background-color: colours.$grey50;
    cursor: not-allowed;
    border: none;
    &:hover {
      color: colours.$text-04;
      background-color: colours.$grey50;
      border: none;
    }
  }
 
}

